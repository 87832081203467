.home {
  --background: url("./my_flavoria_background.png") no-repeat center/cover fixed;
}

.consentform {
  line-height: 2em;
}

@keyframes kievlight {
  from {background-color: #0f0; background-position-x: 0px; background-position-y: 0px;}
  50% {background-color: #afa;}
  to {background-color: #0f0; background-position-x: 168px; background-position-y: 168px;}
}

@keyframes kievdark {
  from {background-color: #050; background-position-x: 0px; background-position-y: 0px;}
  50% {background-color: #020;}
  to {background-color: #050; background-position-x: 168px; background-position-y: 168px;}
}

@keyframes bounce {
  from {scale: 0.3;}
  50% {scale: 1.5;}
  to {scale: 1.0;}
}

@keyframes appear {
  from {opacity: 0.0; scale: 0.3;}
  to {opacity: 1.0;scale: 1.0;}
}

.chickenkiev {
  background-image: url(../../assets/ChickenKiev.png);
  background-size: 84px;
  background-color: #0f0;
  animation-name: kievlight;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.badgeBounce {
  animation-name: bounce;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
}


.chipHighlight {
  animation-name: appear;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
}

.multiline-checkbox {
  white-space: normal;
}

.force-text-wrap {
  text-wrap: auto;
}

.StaticContent * {
  white-space: normal;
}

.StaticContent {
  padding: 18px;
}

.alert-button-danger .alert-button-inner {
  color: var(--ion-color-danger);
}
.md ion-tab-button {
  --color-selected: var(--ion-color-primary);
}
ion-tab-button ion-icon {
  transition: width 0.15s;
}
ion-tab-button.tab-selected ion-icon {
  background: var(--ion-color-tabbar);
  width: 40px;
  border-radius: 20px;
  padding: 1px;
  color: var(--ion-color-tabbar-contrast);
}

.resendComponent {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

ion-accordion > * > ion-icon {
  fill: var(--ion-color-primary);
}

ion-progress-bar::part(track) {
  background: var(--ion-color-dark);
}

a {
  color: var(--ion-color-primary);
}

.loginMyFlavoriaLogo {
  filter: brightness(0.1);
}

.campaign-stamps {
  display: inline-block;
  background: var(--ion-color-tertiary);
  border-radius: 10px;
  padding: 10px;
}

.radioButtonBorders ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;

  border-radius: 10px;
  border: #888 2px dotted;
}

.bottomMenuBar {
  padding-bottom: env(safe-area-inset-bottom, 0px); /* Fix the bottom tab bar on iOS */
}

/*responsive bottom navigation css for smaller devices*/
@media (max-width: 400px) {
  .bottomMenuBar > ion-tab-button {
    min-width: 20% !important;
  }
  .tabButtonLabel {
    font-size: 3vw;
    overflow: visible;
  }
}

.sectionTitle {
  border-bottom: 1px solid var(--ion-color-primary);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeinnscale {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    scale: 1.0;
    opacity: 1;
  }
}

.flavoriapage {
  animation-name: fadeinnscale;
  animation-duration: 0.15s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
