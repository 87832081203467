@use "sass:map";
@use "./ionic.functions.color";

$primary: #686f85;
$secondary: #40485d;
$tertiary: #1b2235;
$success: #2dd55b;
$warning: #ffce31;
$danger: #f24c58;
$light: #aaaaaa;
$medium: #333;
$dark: #1b2235;

$colors: (
  primary: (
    base: $primary,
    contrast: #fff,
    shade: get-color-shade($primary),
    tint: get-color-tint($primary),
  ),
  secondary: (
    base: $secondary,
    contrast: #fff,
    shade: get-color-shade($secondary),
    tint: get-color-tint($secondary),
  ),
  tertiary: (
    base: $tertiary,
    contrast: #fff,
    shade: get-color-shade($tertiary),
    tint: get-color-tint($tertiary),
  ),
  success: (
    base: $success,
    contrast: #fff,
    shade: get-color-shade($success),
    tint: get-color-tint($success),
  ),
  warning: (
    base: $warning,
    contrast: #fff,
    shade: get-color-shade($warning),
    tint: get-color-tint($warning),
  ),
  danger: (
    base: $danger,
    contrast: #fff,
    shade: get-color-shade($danger),
    tint: get-color-tint($danger),
  ),
  light: (
    base: $light,
    contrast: #fff,
    shade: get-color-shade($light),
    tint: get-color-tint($light),
  ),
  medium: (
    base: $medium,
    contrast: #fff,
    shade: get-color-shade($medium),
    tint: get-color-tint($medium),
  ),
  dark: (
    base: $dark,
    contrast: #fff,
    shade: get-color-shade($dark),
    tint: get-color-tint($dark),
  ),
  tabbar: (
    base: #ffffff,
    contrast: #0e121c,
    shade: get-color-shade(#0e121c),
    tint: get-color-tint(#0e121c),
  ),
);

@mixin dark-base-palette() {
  & {
    @each $color-name, $value in $colors {
      --ion-color-#{$color-name}: #{map.get($value, base)};
      --ion-color-#{$color-name}-rgb: #{color-to-rgb-list(
          map.get($value, base)
        )};
      --ion-color-#{$color-name}-contrast: #{map.get($value, contrast)};
      --ion-color-#{$color-name}-contrast-rgb: #{color-to-rgb-list(
          map.get($value, contrast)
        )};
      --ion-color-#{$color-name}-shade: #{map.get($value, shade)};
      --ion-color-#{$color-name}-tint: #{map.get($value, tint)};
    }
  }

  & ion-segment {
    --background: var(--ion-color-tertiary);
    color: white;
  }

  & ion-datetime::part(wheel-item active) {
    color: var(--ion-color-primary);
  }

  & ion-input {
    --background: var(--ion-color-primary) !important; /* XXX: This works for now, but !important should be avoided */
  }

  .loginMyFlavoriaLogo {
    filter: initial;
  }
}

@mixin dark-ios-palette() {
  & {
    --ion-background-color: #0e121c;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-background-color-step-50: #0e121c;
    --ion-background-color-step-100: #181e2f;
    --ion-background-color-step-150: #1e273e;
    --ion-background-color-step-200: #242e47;
    --ion-background-color-step-250: #26314e;
    --ion-background-color-step-300: #324268;
    --ion-background-color-step-350: #354469;
    --ion-background-color-step-400: #485e97;
    --ion-background-color-step-450: #4f68a5;
    --ion-background-color-step-500: #5772b7;
    --ion-background-color-step-550: #607fcd;
    --ion-background-color-step-600: #6586db;
    --ion-background-color-step-650: #7199ff;
    --ion-background-color-step-700: #96b4ff;
    --ion-background-color-step-750: #a7c0ff;
    --ion-background-color-step-800: #eef3ff;
    --ion-background-color-step-850: #d9d9d9;
    --ion-background-color-step-900: #e6e6e6;
    --ion-background-color-step-950: #f2f2f2;
    --ion-text-color-step-50: #f2f2f2;
    --ion-text-color-step-100: #e6e6e6;
    --ion-text-color-step-150: #d9d9d9;
    --ion-text-color-step-200: #cccccc;
    --ion-text-color-step-250: #bfbfbf;
    --ion-text-color-step-300: #b3b3b3;
    --ion-text-color-step-350: #a6a6a6;
    --ion-text-color-step-400: #999999;
    --ion-text-color-step-450: #8c8c8c;
    --ion-text-color-step-500: #808080;
    --ion-text-color-step-550: #737373;
    --ion-text-color-step-600: #666666;
    --ion-text-color-step-650: #595959;
    --ion-text-color-step-700: #4d4d4d;
    --ion-text-color-step-750: #404040;
    --ion-text-color-step-800: #333333;
    --ion-text-color-step-850: #262626;
    --ion-text-color-step-900: #1a1a1a;
    --ion-text-color-step-950: #0d0d0d;
    --ion-item-background: #0e121c;
    --ion-card-background: rgb(19, 21, 34);

  }

  & ion-modal {
    --ion-background-color: var(
      --ion-color-step-100,
      var(--ion-background-color-step-100)
    );
    --ion-toolbar-background: var(
      --ion-color-step-150,
      var(--ion-background-color-step-150)
    );
    --ion-toolbar-border-color: var(
      --ion-color-step-250,
      var(--ion-background-color-step-250)
    );
  }

  & ion-segment-button {
    --color: white;
    --color-checked: var(--ion-color-primary);
  }

  & .chickenkiev {
    background-color: #0f0;
    animation-name: kievdark;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

}

@mixin dark-md-palette() {
  & {
    --ion-background-color: #0e121c;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-background-color-step-50: #1e1e1e;
    --ion-background-color-step-100: #2a2a2a;
    --ion-background-color-step-150: #363636;
    --ion-background-color-step-200: #414141;
    --ion-background-color-step-250: #4d4d4d;
    --ion-background-color-step-300: #595959;
    --ion-background-color-step-350: #656565;
    --ion-background-color-step-400: #717171;
    --ion-background-color-step-450: #7d7d7d;
    --ion-background-color-step-500: #898989;
    --ion-background-color-step-550: #949494;
    --ion-background-color-step-600: #a0a0a0;
    --ion-background-color-step-650: #acacac;
    --ion-background-color-step-700: #b8b8b8;
    --ion-background-color-step-750: #c4c4c4;
    --ion-background-color-step-800: #d0d0d0;
    --ion-background-color-step-850: #dbdbdb;
    --ion-background-color-step-900: #e7e7e7;
    --ion-background-color-step-950: #f3f3f3;
    --ion-text-color-step-50: #f3f3f3;
    --ion-text-color-step-100: #e7e7e7;
    --ion-text-color-step-150: #dbdbdb;
    --ion-text-color-step-200: #d0d0d0;
    --ion-text-color-step-250: #c4c4c4;
    --ion-text-color-step-300: #b8b8b8;
    --ion-text-color-step-350: #acacac;
    --ion-text-color-step-400: #a0a0a0;
    --ion-text-color-step-450: #949494;
    --ion-text-color-step-500: #898989;
    --ion-text-color-step-550: #7d7d7d;
    --ion-text-color-step-600: #717171;
    --ion-text-color-step-650: #656565;
    --ion-text-color-step-700: #595959;
    --ion-text-color-step-750: #4d4d4d;
    --ion-text-color-step-800: #414141;
    --ion-text-color-step-850: #363636;
    --ion-text-color-step-900: #2a2a2a;
    --ion-text-color-step-950: #1e1e1e;

    --ion-item-background: #1b2235;
    --ion-toolbar-background: #1b2235;
    --ion-tab-bar-background: #1b2235;
    --ion-card-background: #1b2235;
  }

  & ion-segment-button {
    --background-checked: var(--ion-color-secondary);
    --color: white;
    --color-checked: var(--ion-color-primary);
    --indicator-color: var(--ion-color-primary);
    --indicator-height: 4px;
  }
  
}
